<template>
	<div class="section_footer">
		<div class="layout_footer_link">
			<ul class="com_link">
				<li><a href="http://daou.com/" target="_blank">회사소개</a></li>
				<li>
					<a href="https://daouoffice.com/terms.jsp" target="_blank">이용약관</a>
				</li>
				<li class="em">
					<a href="https://daouoffice.com/privacy.jsp" target="_blank">개인정보처리방침</a>
				</li>
				<li>
					<a href="https://daouoffice.com/spam.jsp" target="_blank">스팸정책</a>
				</li>
				<li>
					<a href="https://www.daouoffice.com/partnership.jsp" target="">제휴문의</a>
				</li>
			</ul>
		</div>

		<div class="layout_footer">
			<div class="layout_footer_left">
				<div class="wrap_copyright">
					<address>
						<span class="txt"
							>경기도 용인시 수지구 디지털벨리로 81, 6층(죽전동, 디지털스퀘어) &nbsp;|&nbsp; ㈜다우기술 &nbsp;|&nbsp;
							대표이사 김윤덕
						</span>
						<span class="txt"
							>사업자등록번호 220 - 81 - 02810 | 통신판매업신고 제 2010 – 용인수지 – 114호 &nbsp;
							<a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208102810" target="_blank">
								<span class="label_state normal" target="_blank">사업자 정보 확인</span>
							</a>
						</span>
						<span class="txt">호스팅 서비스 제공자 : ㈜ 다우기술 | Email: daouoffice@daou.co.kr </span>
						<span class="txt copyright">Copyright DAOU TECHONOLOGY INC. All rights reserved.</span>
					</address>
				</div>
			</div>

			<div class="layout_footer_right">
				<div class="wrap_cs">
					<span class="txt tit">상담문의 / 1599-9460</span>
					<a herf="" @click="openDaouCare()">
						<span class="txt em">채팅상담</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		openDaouCare() {
			window.open(this.$store.getters.isSaaS ? window.__env.daouCareUrl : window.__env.daouManualUrl);
		},
	},
};
</script>

<style></style>
