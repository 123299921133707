<template>
	<div class="section_sidebar">
		<div class="gnb">
			<div class="gnb_group">
				<div class="gnb_menu" v-for="menu in computedMenus" :key="menu.id">
					<div class="gnb_menu">
						<a class="gnb_tit" :class="isOpenMenu(menu.name) ? 'show' : 'hide'" v-on:click="openDept(menu.name)">
							<span class="ic_set ic_accordion"></span>
							<span class="txt">{{ menu.name }}</span>
						</a>
						<transition name="slide">
							<ul class="gnb_sub" v-if="isOpenMenu(menu.name)">
								<li v-for="childMenu in menu.child" :key="childMenu.id">
									<router-link
										:to="childMenu.url"
										class="tit"
										:class="isActiveRoute(childMenu.url) > 0 ? 'on' : 'folded'"
									>
										<span class="txt">{{ childMenu.name }}</span>
									</router-link>
								</li>
							</ul>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseSideBar',
	data() {
		return {
			loginMembership: {},
			foldedClass: 'folded',
			currentMenu: '',
			openedMenu: [],
			// menus: [],
			accessMenus: ['MYSTORE', 'ORDER'],
		};
	},
	computed: {
		isOpenMenu() {
			return (currentMenu) => {
				return this.openedMenu.includes(currentMenu);
			};
		},
		isActiveMenu() {
			return (currentMenu) => {
				const active = this.$route.matched.filter((item) => {
					return item.meta.menuGroup != undefined && item.meta.menuGroup == currentMenu;
				});
				return active.length;
			};
		},
		isActiveRoute() {
			return (currentRouteURL) => {
				if (currentRouteURL == '/order/addExtension') {
					if (
						this.$route.path.indexOf(currentRouteURL) > -1 ||
						this.$route.path == '/order/Purchase' ||
						this.$route.path == '/order/Complete'
					) {
						return true;
					} else {
						return false;
					}
				} else if (currentRouteURL == '/user/myStore/OrderHistory') {
					if (
						this.$route.path.indexOf(currentRouteURL) > -1 ||
						this.$route.path.indexOf('/user/myStore/OrderDetail') > -1
					) {
						return true;
					} else {
						return false;
					}
				} else if (this.$route.path.indexOf(currentRouteURL) > -1) {
					return true;
				} else {
					return false;
				}
			};
		},
		computedMenus() {
			return this.menus.filter((menu) => {
				const child = menu.child.filter((star) => {
					return this.accessMenus.some((accessMenu) => star.accessMenuType === accessMenu);
				});
				menu.child = child;
				return child.length;
			});
		},
		menus() {
			return this.$store.state.login.accessMenus;
		},
	},
	created() {
		this.getMembership();
	},
	methods: {
		async getMembership() {
			const token = sessionStorage.getItem('userToken');
			if (token) {
				const result = await this.$store.dispatch('USER_SSO', token);
				this.loginMembership = await this.$store.dispatch('GETMEMBERSHIP');
				await this.setMenus();
			} else {
				this.loginMembership = await this.$store.dispatch('GETMEMBERSHIP');
				await this.setMenus();
			}
		},
		openDept(menu) {
			if (this.openedMenu.includes(menu)) {
				const index = this.openedMenu.indexOf(menu);
				this.openedMenu.splice(index, 1);
			} else {
				this.openedMenu.push(menu);
			}
		},
		async setMenus() {
			// let menus = sideMenu.menus;
			// if (!this.loginMembership.enableAccessStore) {
			// 	// 스토어 접근여부
			// 	menus = menus.filter((menu) => menu.key === 'membership');
			// 	menus[0].child = menus[0].child.filter(
			// 		(menu) => menu.key !== 'orderHistory',
			// 	);
			// }

			// this.menus = menus;
			/*
			if (this.loginMembership.partnerSimpleModel.salesType == 'RESELLER') {
				// 리셀러사 주문/결제 내역 block
				this.menus[0].child.splice(2, 1);
				// 리셀러사 store block
				if (this.menus[1]) {
					this.menus.splice(1, 1);
				}
			}*/
			const oneDeptMenuNames = this.menus.map((menu) => {
				return menu.name;
			});
			this.openedMenu = oneDeptMenuNames;
		},
	},
};
</script>

<style>
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
