import eventBus from '@/utils/bus';
import { hasElement } from '@/utils/utils';

export default {
	data() {
		return {
			isOpenedLayer: false,
		};
	},
	mounted() {
		eventBus.$on('backdrop', (element) => {
			if (!this.$refs.backdropLayer || !element) return;
			if (!hasElement(this.$refs.backdropLayer, element)) {
				this._hideLayer();
			}
		});
	},
	methods: {
		handleBackdropButtonClick(e) {
			this.isOpenedLayer = !this.isOpenedLayer;
			eventBus.$emit('backdrop', e.target);
		},
		handleBackdrop(e) {
			eventBus.$emit('backdrop', e.target);
		},
		_hideLayer() {
			this.isOpenedLayer = false;
		},
	},
};
