<template>
	<div class="section_container theme_user" @click="handleBackdrop">
		<div class="section_header">
			<h1 class="logo">
				<img src="@/assets/images/logo_do.png" alt="다우오피스" title="다우오피스" />
				<span class="txt">멤버십 스토어</span>
			</h1>
		</div>
		<div class="section_body">
			<base-side-bar></base-side-bar>
			<div class="section_content">
				<content-top></content-top>
				<div class="content_body">
					<!--container-->
					<router-view />

					<!--//container-->
				</div>
			</div>
		</div>
		<base-footer></base-footer>
	</div>
</template>

<script>
import BaseSideBar from '@/components/common/BaseSideBar.vue';
import ContentTop from '@/components/common/ContentTop.vue';
import BaseFooter from '@/components/common/BaseFooter.vue';
import backdrop from '@/components/common/backdrop';
import NoticeLayer from '@/components/common/NoticeLayer';
import { isNoticeCloseForever } from '@/utils/cookies';

export default {
	mixins: [backdrop],
	components: {
		BaseSideBar,
		ContentTop,
		BaseFooter,
	},
	created() {
		document.title = '다우오피스 멤버십 스토어';
		window.addEventListener('scroll', this.detectWindowScrollY);
		const token = this.$route.query.token;
		if (token) {
			this.userSso(token);
		}
		if (window.__env.showNoticeLayer) {
			this.openNoticeModal();
		}
	},
	methods: {
		async userSso(token) {
			if (token) {
				sessionStorage.setItem('userToken', token);
				const { data } = await this.$store.dispatch('USER_SSO', token);
				sessionStorage.setItem('loginId', data.loginId);
			}
			this.$router.push('/user/myStore/MembershipStatus');
		},
		openNoticeModal() {
			if (isNoticeCloseForever()) {
				return;
			}

			this.$modal.show(
				NoticeLayer,
				{},
				{
					height: '880',
					width: '700',
					draggable: true,
					clickToClose: true,
				},
			);
		},
	},
};
</script>
