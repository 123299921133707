<template>
	<div>
		<div class="com_layer_notice">
			<div class="lay_header">
				<a class="btn_close" v-on:click="close" title="닫기"><span class="ic_set ic_close"></span></a>
			</div>
			<!--content-->
			<div class="lay_body">
				<p>
					안녕하세요 다우오피스입니다.
					<br />
					다우오피스 주문(계약)과 관련해 고객 법인정보 및 계약 담당자 개인정보 보호 강화 조치로
					<em>2023년 9월 14일(목)부터 기존 멤버십스토어는 종료되고 새로운 고객포털 서비스가 제공됩니다.</em>
					<br />
					기존 멤버십스토어에서는 계약 정보, 구매 이력, 계약 및 세금계산서 담당자의 개인정보 등에 다수의 사이트 관리자가
					접근이 가능했으나
					<br />고객포털 오픈 후부터는 다우오피스 주문(계약) 담당자만 고객포털에 가입하여 계약 및 담당자 개인정보 등을
					관리할 수 있게 됩니다.<br />
					<br />
					9월 14일 이후부터는 반드시
					<em>다우오피스 주문(계약)담당자는 기업회원 가입을 통해 기업 대표 계정으로 </em>서비스를 이용해주시기 바랍니다.
					<br />
					📢
					<a
						href="https://care.daouoffice.co.kr/hc/ko/articles/19612430792217"
						target="_blank"
						style="color: #000; text-decoration: none"
					>
						내용 자세히 보기
					</a>
				</p>
				<ol class="highlight">
					<li>고객포털 기업회원 가입 / 로그인을 통해 담당 권한자만 접근해 주세요.</li>
					<li>고객포털 로그인 후 회사 법인정보와 멤버십 정보 등이 주문(계약) 정보와 맞는지 반드시 확인해 주세요.</li>
					<li>
						두 개 이상의 법인으로 다우오피스를 이용하고 계신 경우 (공유형 멀티컴퍼니) 각 컴퍼니마다 다른 계약 담당자를
						설정하셔야 합니다.
					</li>
					<li>
						하나의 법인으로 여러 서비스를 이용하고 계신 경우에는 고객포털에서 모든 멤버십(계약) 정보를 조회하실 수
						있습니다. 계약정보가 모두 노출되지 않을 경우 영업담당자나 고객센터로 문의 주시기 바랍니다.
					</li>
				</ol>
				<p class="mt-20">위와 관련한 문의 사항은 1577-3019로 연락주시기 바랍니다.</p>
				<p class="">감사합니다.</p>
			</div>
			<div class="outside_button" style="background-color: #2a2d33" v-on:click="closeForever">
				<button>
					<i class="ic_set ic_close"></i>
					다시 보지 않기
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { noticeCloseForever } from '@/utils/cookies';

export default {
	name: 'NoticeLayer',
	methods: {
		close() {
			this.$emit('close');
		},
		closeForever() {
			noticeCloseForever();
			this.$emit('close');
		},
	},
};
</script>
<style scoped></style>
