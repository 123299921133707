<template>
	<div class="content_top" v-if="crumbs && crumbs.length > 0">
		<h2>{{ crumbs[0].upper }}</h2>
		<div class="wrap_breadcrumb">
			<ol class="com_breadcrumbs">
				<li class="item">
					<a><span class="ic_set ic_home" title="Home"></span></a>
				</li>
				<li v-for="(item, index) in crumbs" :key="index" class="item" :class="{ active: isLast(crumbs, index) }">
					{{ item.text }}
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ContentTop',
	computed: {
		isLast() {
			return (menues, index) => {
				return menues.length - 1 === index;
			};
		},
		crumbs() {
			const metaMap = {
				'myStore/OrderHistory': {
					breadCrumb: `주문/${this.$store.getters.isSaaS ? '구매' : '계약'}내역`,
				},
				'myStore/OrderDetail/:id': {
					breadCrumb: `주문/${this.$store.getters.isSaaS ? '구매' : '계약'}내역 상세`,
				},
			};
			const breadcrumbArray = [];
			this.$route.matched.forEach((item, index) => {
				if (index > 0) {
					const meta = metaMap[item.path] || item.meta;
					breadcrumbArray.push({
						path: item.path,
						upper: item.meta.upperCrumb,
						text: meta.breadCrumb,
					});
				}
			});
			return breadcrumbArray;
		},
	},
};
</script>

<style></style>
